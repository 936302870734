import styled from "styled-components";
// Image
import featuresImg01 from '../../../assets/LandingNew/Choose/choose_bg_01.png'
import featuresImg02 from '../../../assets/LandingNew/Choose/choose_bg_02.png'
import featuresImg03 from '../../../assets/LandingNew/Choose/choose_bg_03.png'
import { motion } from "framer-motion";

export const ChooseNewContainer = styled.div`
  padding-top: 100px;
  overflow: hidden;
`;
export const ChooseNewWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 8vw;
  @media screen and (max-width: 767px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;
export const ChooseNewWrapperTitle = styled.div`
  text-align: center;
`
export const ChooseList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
`;
export const ChooseItem = styled(motion.li)`
  width: calc(100% / 2 - 7.6vw);
  min-height: 445px;
  position: relative;
  border-radius: 40px;
  background: center / cover no-repeat;
  padding: 2.8vw;
  &:nth-child(1) {
    background-image: url(${featuresImg01})
  }
  &:nth-child(2) {
    background-image: url(${featuresImg02})
  }
  &:nth-child(3) {
    background-image: url(${featuresImg03})
  }
  &.is-full {
    width: 100%;
  }
  @media (min-width: 767.98px) and (max-width: 1023px) {
    min-height: 200px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    min-height: 150px;
    padding: 30px 40px 56px;
  }
`;
export const ChooseItemInner = styled(motion.div)``
export const ChooseItemContent = styled(motion.div)`
  max-width: 30vw;
  width: 100%;
  @media screen and (max-width: 767px) {
    max-width: unset;
  }
`
export const ChooseItemTitle = styled.h3`
  color: #B5EBFF;
  font-size: 2.22222vw;
  font-weight: 400;
  &.light {
    color: #D6F3FF;
  }
  @media screen and (max-width: 767px) {
    font-size: 28px;
  }
`
export const ChooseItemText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  &.light {
    color: #EDFAFF;
  }
`
export const ChooseItemArrow = styled.div`
  position: absolute;
  right: 15px;
  bottom: 10px;
  width: 2.917vw;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 30px;
  }
`
