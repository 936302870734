import { Link } from "react-router-dom";
import styled from "styled-components";
import arrow from "../../assets/Header/header_arrow.png";
import arrowHover from "../../assets/Header/header_arrow_hover.png";

export const HeaderDashboardContainer = styled.header`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;
export const HeaderDashboardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 83vw;
  margin: 0 auto;
  padding: 1.6vw 1vw;
  @media screen and (max-width: 767px) {
    padding: 15px 10px;
    max-width: 90%;
  }
`;
export const HDLogo = styled(Link)`
  display: flex;
  width: 6.95vw;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 100px;
  }
`;

// choose language
export const ButtonChangeLanguage = styled.div`
  width: 2.9167vw;
  height: 2.9167vw;
  cursor: pointer;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 1.737vw;
    height: 1.737vw;
  }
  &.light {
    background-color: #b8b8c1;
  }
  @media screen and (max-width: 767px) {
    width: 36px;
    height: 36px;
    img {
      width: 19px;
      height: 19px;
    }
  }
`;
export const HeaderLanguageTitle = styled.p`
  color: #fff;
  font-family: Dirtyline;
  font-size: 1.11111vw;
  font-style: normal;
  line-height: 96%; /* 15.36px */
  text-transform: lowercase;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;
export const HeaderLanguageClose = styled.div`
  width: 1.9167vw;
  height: 1.9167vw;
  cursor: pointer;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  img {
    cursor: pointer;
    width: 0.837vw;
    height: 0.837vw;
  }
  @media screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
    img {
      width: 15px;
      height: 15px;
    }
  }
`;
export const ItemLanguage = styled.li`
  padding: 0.4vw;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.26s ease;
  font-size: 1.11111vw;
  &:hover {
    background: rgba(74, 73, 82, 0.5);
  }
  &.is-active {
    background: var(--Neutral-600, #5e5e6b);
  }
  &:not(:last-child) {
    margin-bottom: 0.4vw;
  }
  span {
    margin-left: 1vw;
    text-transform: uppercase;
    font-weight: 400;
    color: #a1a7aa;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    padding: 6px;
    margin-bottom: 5px;
  }
`;
export const BlockChangeLanguage = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 123%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16.023px;
  border: 1px solid var(--Neutral-700, #4d4d57);
  background: #1c1c1e;
  box-shadow: 0px 0px 114.031px 0px rgba(106, 126, 228, 0.15);
  width: 13vw;
  /* transform: translateY(-100%); */
  transition: all 0.26s ease;
  padding: 16px;
  &.is-show {
    opacity: 1;
    pointer-events: auto;
  }
  @media screen and (max-width: 767px) {
    width: 280px;
    margin-left: -44%;
  }
  &.light {
    border-color: transparent;
    background: #FFF;
    box-shadow: 0px 0px 114.031px 0px rgba(106, 126, 228, 0.15);
    ${HeaderLanguageTitle} {
      color: #43424A;
    }
    ${HeaderLanguageClose} {
      background-color: #B8B8C1;
    }
    ${ItemLanguage} {
      &.is-active {
        background: var(--Neutral-200, #D9D9DE);
        color: #43424A;
      }
      &:hover {
        background: var(--Neutral-50, #F7F7F8);
      }
    }
  }
`;
export const HeaderLanguage = styled.div`
  position: relative;
  border-radius: 53px;
  transition: all .26s ease;
`;
export const HeaderLanguageContentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ListLanguage = styled.ul``;

//end choose language
export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 3vw;
  @media screen and (max-width: 767px) {
    margin-right: 0;
    padding-left: 0;
  }
`;

export const ButtonLighMode = styled.div`
  width: 2.9167vw;
  height: 2.9167vw;
  cursor: pointer;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  img {
    width: 1.737vw;
    height: 1.737vw;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.8685vw;
    margin-left: -0.8685vw;
    transition: transform 0.26s ease;
  }
  .moon {
    transform: translateY(-4vw);
  }
  &.light {
    background-color: #b8b8c1;
    .sun {
      transform: translateY(-4vw);
    }
    .moon {
      transform: translateY(0);
    }
  }
  @media screen and (max-width: 767px) {
    width: 36px;
    height: 36px;
    img {
      width: 19px;
      height: 19px;
      margin-top: -10px;
      margin-left: -9px;
    }
    .moon {
      transform: translateY(-40px);
    }
    &.light {
      .sun {
        transform: translateY(-40px);
      }
      .moon {
        transform: translateY(0);
      }
    }
  }
`;
export const ActionSocial = styled.ul`
  display: flex;
  align-items: center;
  width: 2.9167vw;
  height: 2.9167vw;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.1);
  overflow: hidden;
  transition: all 0.26s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    width: 8.4vw;
    border-radius: 50px;
    background-color: #27282b;
  }
  &.light {
    background-color: #b8b8c1;
  }
  & > li {
    display: flex;
    width: 2.9167vw;
    height: 2.9167vw;
    justify-content: center;
    flex-shrink: 0;
    margin-left: -2px;
    position: relative;
    a {
      display: flex;
      width: 2.223vw;
      flex-shrink: 0;
      &:hover {
        .image-hover {
          opacity: 1;
        }
        .image-normal {
          opacity: 0;
        }
      }
      img {
        width: 1.423vw;
        transition: opacity 0.26s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .image-hover {
        opacity: 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 32px;
    height: 32px;
    display: none;
    &:hover {
      width: 90px;
      border-radius: 50px;
    }
    & > li {
      width: 32px;
      a {
        width: 32px;
        height: 32px;
      }
    }
  }
`;
export const ButtonLaunchApp = styled.a`
  font-size: 1.11111vw;
  color: #fff;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.26s ease;
  position: relative;
  padding-right: 3.6vw;
  height: 2.8vw;
  display: flex;
  align-items: center;
  font-family: Dirtyline;
  text-transform: lowercase;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &:before {
    width: 2.8vw;
    height: 2.8vw;
    border-radius: 50%;
    background-color: #3a3a40;
    right: 0;
    top: 50%;
    transition: background 0.26s ease;
    margin-top: -1.4vw;
  }
  &:after {
    background: url(${arrow}) center / contain no-repeat;
    width: 1.181vw;
    height: 0.97223vw;
    right: 0.8vw;
    top: 50%;
    margin-top: -0.486115vw;
  }
  &:hover {
    color: #b5ebff;
    &:before {
      background-color: #b5ebff;
    }
    &:after {
      background-image: url(${arrowHover});
    }
  }
  &.light {
    &:before {
      background-color: #b8b8c1;
    }
    &:after {
      background-image: url(${arrowHover});
    }
    &:hover {
      &:after {
        background-image: url(${arrow});
      }
    }
    p {
      color: #5e5e6b;
    }
  }
  p {
    padding-top: 0.5vw;
  }
  @media screen and (max-width: 767px) {
    font-size: 17px;
    padding-right: 42px;
    &:before {
      width: 36px;
      height: 36px;
      margin-top: -18px;
    }
    &:after {
      width: 15px;
      height: 12px;
      right: 11px;
      margin-top: -6px;
    }
    p {
      padding-top: 5px;
      text-align: right;
    }
  }
`;
