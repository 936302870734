import {
  CommunityList,
  ContentInput,
  FooterContainer,
  FooterItemFunction,
  FooterLast,
  FooterLastItem,
  FooterListFunction,
  FooterLogo,
  FooterMain,
  FooterNav,
  FooterSocial,
  FooterSubscribe,
  FooterWrapper,
  SubscribeForm,
  SubscribeWrapper,
} from "./styled";
import footer_logo_02 from "../../assets/Footer/footer_logo_02.svg";
import footer_logo_03 from "../../assets/Footer/footer_logo_03.svg";
import { Link } from "react-router-dom";
import text01 from "../../assets/Footer/footer_text_01.png";
import text02 from "../../assets/Footer/footer_text_02.png";
import text03 from "../../assets/Footer/footer_text_03.png";
import text04 from "../../assets/Footer/footer_text_04.png";
import text05 from "../../assets/Footer/footer_text_05.png";
import text06 from "../../assets/Footer/footer_text_06.png";
import text07 from "../../assets/Footer/footer_text_07.png";
import email from "../../assets/Footer/email.svg";
import InputCommon from "../Input";
import ButtonCommon from "../../assets/Common/Button";
import { useContext, useEffect, useRef, useState } from "react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { ContextProviderWrapper } from "../Context";
import { motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";

const FooterNew = () => {
  const { isDesktop, theme, lng } = useContext(ContextProviderWrapper)!;
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [addClass, setAddClass] = useState(false);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  useMotionValueEvent(scrollYProgress, "change", (latest: any) => {
    const convertValue: any = latest;
    if (convertValue > 0.5) {
      setAddClass(true);
    } else {
      setAddClass(false);
    }
  });
  return (
    <div ref={ref}>
      <FooterContainer>
        <FooterWrapper>
          <FooterMain>
            <FooterLogo to={"/"}>
              <img
                src={theme === "light" ? footer_logo_03 : footer_logo_02}
                alt="logo"
              />
            </FooterLogo>
            <p className={theme === "light" ? "light" : ""}>
              <Trans>
                footer.We are here to provide all you need for the best trading
                experience and optimal advances on TON blockchain.
              </Trans>
            </p>
            <FooterListFunction>
              {listFunction.map((item: any, index: number) => (
                <FooterItemFunction className={(lng === 'ar' || lng==='pt'|| lng==='ru') ? 'is-font-size' : ''} key={index}>
                  <Link to="#" className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
                    <Trans>{item.text}</Trans>
                  </Link>
                </FooterItemFunction>
              ))}
            </FooterListFunction>
            <SubscribeWrapper>
              <SubscribeForm className={theme === "light" ? "light" : ""}>
                <ContentInput className={theme === "light" ? "light" : ""}>
                  <span
                    className={`title-input ${
                      theme === "light" ? "light" : ""
                    }`}
                  >
                    <Trans>footer.Your email</Trans>
                  </span>
                  <InputCommon
                    prefix={<img src={email} alt="icon" />}
                    placeHolder={`${t("footer.Please input your email")}`}
                  />
                </ContentInput>
                <ButtonCommon
                  className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}
                  title="footer.Subscribe"
                />
              </SubscribeForm>
            </SubscribeWrapper>
          </FooterMain>
          {/* <FooterNav>
            {navData.map((item, index) => {
              return (
                <li key={index}>
                  <h2>{item.title}</h2>
                  <ul>
                    {item.subLinks.map((link, i) => {
                      return (
                        <li key={i}>
                          <Link to={link.href}>{link.title}</Link>
                        </li>
                      );
                    })}
                    {item.title === "Community" && (
                      <CommunityList>
                        <li>
                          <Link
                            to={"https://twitter.com/KibbleExchange"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={community_1} alt="icon" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"https://t.me/KibbleOfficial"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={community_2} alt="icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"} target="_blank" rel="noreferrer">
                            <img src={community_3} alt="icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"} target="_blank" rel="noreferrer">
                            <img src={community_4} alt="icon" />
                          </Link>
                        </li>
                      </CommunityList>
                    )}
                  </ul>
                </li>
              );
            })}
          </FooterNav> */}
          <FooterSocial>
            {socialData.map((item, index) => {
              return (
                <li
                  key={index}
                  className={addClass && isDesktop ? "is-animation" : ""}
                >
                  <p>
                    <Link to={item.href} target="_blank">
                      <Trans>{item.text}</Trans>
                    </Link>
                  </p>
                </li>
              );
            })}
          </FooterSocial>
        </FooterWrapper>
      </FooterContainer>
      <FooterLast>
        {listText.map((item: any, index: number) => (
          <FooterLastItem
            whileHover={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <img src={item.text} alt="" />
          </FooterLastItem>
        ))}
      </FooterLast>
    </div>
  );
};

const listFunction = [
  {
    text: "features.Swap",
  },
  {
    text: "footer.Liquidity",
  },
  {
    text: "footer.Farming",
  },
  {
    text: "footer.Staking",
  },
];

const socialData = [
  {
    text: "TELEGRAM",
    href: "https://t.me/KibbleChat",
  },
  {
    text: "TWITTER",
    href: "https://twitter.com/KibbleExchange",
  },
  {
    text: "DISCORD",
    href: "https://discord.com/invite/gB8cpNeBb8",
  },
  {
    text: "MEDIUM",
    href: "https://kibble.medium.com/",
  },
  {
    text: "YOUTUBE",
    href: "https://www.youtube.com/@KibbleExchange",
  },
];

const listText = [
  {
    text: text01,
  },
  {
    text: text02,
  },
  {
    text: text03,
  },
  {
    text: text04,
  },
  {
    text: text05,
  },
  {
    text: text06,
  },
  {
    text: text07,
  },
];

export default FooterNew;
