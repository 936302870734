import styled from "styled-components";

export const TeamContainer = styled.div`
  padding-top: 100px;
  overflow: hidden;
  position: relative;
  .text-bottom {
    margin-top: -2.5vw;
  }
`;
export const TeamWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 8vw;
  @media screen and (max-width: 767px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;
export const Teamtext = styled.p`
  max-width: 25vw;
  margin-left: auto;
  margin-bottom: 50px;
  font-size: 1.388888vw;
  font-weight: 400;
  line-height: 1.8vw;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    max-width: unset;
    line-height: 1;
  }
`;
export const TeamInfo = styled.div`
  margin-left: 8vw;
  margin-bottom: 18vw;
  .swiper-slide {
    max-width: 40.63vw;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    margin-left: 5vw;
    .swiper-slide {
      max-width: 275px;
    }
  }
`;
export const TeamItemImage = styled.figure`
  width: 8.0557vw;
  height: 8.0557vw;
  margin-bottom: 1vw;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  @media screen and (max-width: 767px) {
    width: 66px;
    height: 66px;
    margin-bottom: 10px;
  }
`;
export const TeamItemDetail = styled.div`
  padding: 2.8vw;
  transition: transform 0.3s ease;
  background-color: #3a3a40;
  border-radius: 3vw;
  min-height: 26.1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.light {
    background-color: #D9D9DE;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    min-height: 330px;
  }
`;
export const TeamItemArrow = styled.div`
  position: absolute;
  z-index: 2;
  top: 1.2vw;
  right: 1.2vw;
  background-color: #f0cc54;
  width: 3.473vw;
  height: 3.473vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 1.4585vw;
  }
  @media screen and (max-width: 767px) {
    width: 25px;
    height: 25px;
    right: 10px;
    top: 10px;
    img {
      width: 14px;
    }
  }
`;
export const TeamItem = styled.div`
  min-height: 444px;
  position: relative;
`;
export const TeamItemTitle = styled.p`
  font-size: 2.22222vw;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin-bottom: 0.5vw;
  &.light {
    color: #4D4D57;
  }
  @media screen and (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 3px;
  }
`;
export const TeamItemPosition = styled.span`
  display: block;
  color: #1eb0ff;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 1.11111vw;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;
export const TeamItemText = styled.p`
  color: #b8b8c1;
  font-weight: 400;
  font-size: 1.11111vw;
  margin-bottom: 20px;
  line-height: 1.6vw;
  &.light {
    color: #6E6E7C;
  }
  @media screen and (max-width: 767px) {
    font-size: 15px;
    line-height: 1.3;
  }
`;
export const TeamSocial = styled.ul`
  display: flex;
  align-items: center;
  gap: 1vw;
  @media screen and (max-width: 767px) {
    gap: 10px;
  }
`;
export const TeamItemSocial = styled.li`
  a {
    display: block;
    width: 2.223vw;
  }
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    a {
      width: 32px;
    }
  }
`;

export const TextSlide = styled.p`
  color: #d6f3ff;
  font-family: Dirtyline;
  font-size: 9.0278vw;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
  margin-right: 2.3vw;
  &.light {
    color: #6E6E7C;
  }
  &.is-vi {
    line-height: 1.4;
  }
  @media screen and (max-width: 767px) {
    font-size: 70px;
    letter-spacing: 0;
    margin-right: 20px;
  }
`;
