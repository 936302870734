import React, { useContext, useRef } from "react";
import {
  BlockVideoConainer,
  BlockVideoWrapper,
  BlockVideoWrapperInner,
} from "./styled";
import { useScroll, useTransform } from "framer-motion";
import { ContextProviderWrapper } from "../../../components/Context";

const BlockVideo = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0.1, 0.5], [0.2, 1]);
  const radius = useTransform(scrollYProgress, [0.2, 0.5], [1000, 32]);
  const topProgress = useTransform(scrollYProgress, [0.2, 0.8], [-150, 0]);
  const topProgressSp = useTransform(scrollYProgress, [0.2, 0.8], [0, 0]);
  const { theme, isDesktop, isTablet } = useContext(ContextProviderWrapper)!;
  return (
    <BlockVideoConainer ref={ref} className={theme === 'light' ? 'light' : ''}>
      <BlockVideoWrapper>
        <BlockVideoWrapperInner
          style={{
            scale: (isDesktop || isTablet) && scaleProgress,
            borderRadius: (isDesktop || isTablet) && radius,
            top: (isDesktop || isTablet) ? topProgress : topProgressSp
          }}
        >
          <video autoPlay muted loop playsInline>
            <source src="./video/video.mp4" type="video/mp4" />
          </video>
        </BlockVideoWrapperInner>
      </BlockVideoWrapper>
    </BlockVideoConainer>
  );
};

export default BlockVideo;
