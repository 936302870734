import React, { useContext, useRef } from "react";
import {
  PartnersContainer,
  PartnersItem,
  PartnersList,
  PartnersWrapper,
} from "./styled";
import { TitlePrimary } from "../../../assets/Common/TitleSection/styled";
import { motion, useScroll, useTransform } from "framer-motion";
import { Trans } from "react-i18next";
import { ContextProviderWrapper } from "../../../components/Context";
import img01 from "../../../assets/LandingNew/Partners/partner_img_01.png";
import img02 from "../../../assets/LandingNew/Partners/partner_img_02.png";
import img03 from "../../../assets/LandingNew/Partners/partner_img_03.png";
import img04 from "../../../assets/LandingNew/Partners/partner_img_04.png";
import img05 from "../../../assets/LandingNew/Partners/partner_img_05.png";
import img06 from "../../../assets/LandingNew/Partners/partner_img_06.png";
import img07 from "../../../assets/LandingNew/Partners/partner_img_07.png";
import img08 from "../../../assets/LandingNew/Partners/partner_img_08.png";
import img09 from "../../../assets/LandingNew/Partners/partner_img_09.png";
import img10 from "../../../assets/LandingNew/Partners/partner_img_10.png";
import img11 from "../../../assets/LandingNew/Partners/partner_img_11.png";
import img12 from "../../../assets/LandingNew/Partners/partner_img_12.png";
import img13 from "../../../assets/LandingNew/Partners/partner_img_13.png";
import img14 from "../../../assets/LandingNew/Partners/partner_img_14.png";
import img15 from "../../../assets/LandingNew/Partners/partner_img_15.png";
import img16 from "../../../assets/LandingNew/Partners/partner_img_16.png";
import img17 from "../../../assets/LandingNew/Partners/partner_img_17.png";
import img18 from "../../../assets/LandingNew/Partners/partner_img_18.png";
import img19 from "../../../assets/LandingNew/Partners/partner_img_19.png";
import img20 from "../../../assets/LandingNew/Partners/partner_img_20.png";
import img21 from "../../../assets/LandingNew/Partners/partner_img_21.png";
import img22 from "../../../assets/LandingNew/Partners/partner_img_22.png";
import Marquee from "react-fast-marquee";


const Partners = () => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const backgroundPosition = useTransform(
    scrollYProgress,
    [0, 0.5],
    ["100%", "0%"]
  );
  const leftProgress = useTransform(scrollYProgress, [0, 0.5], [100, 0]);
  const leftProgress2 = useTransform(scrollYProgress, [0, 0.5], [200, 0]);
  const opacityProgress = useTransform(scrollYProgress, [0.5, 1], [0, 1]);
  const BottomProgress = useTransform(scrollYProgress, [0.5, 1], [-150, 0]);

  return (
    <PartnersContainer ref={ref}>
      <PartnersWrapper>
        <TitlePrimary className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
          <motion.span
            style={{
              backgroundPositionX:
                (isDesktop || isTablet) && backgroundPosition,
              left: (isDesktop || isTablet) && leftProgress,
            }}
          >
            {/* <Trans>team.OUR</Trans> */}
            <Trans>INVESTORS &</Trans>
          </motion.span>
          <motion.span
            style={{
              backgroundPositionX:
                (isDesktop || isTablet) && backgroundPosition,
              left: (isDesktop || isTablet) && leftProgress2,
            }}
          >
            {/* <Trans>partners.PARTNERS</Trans> */}
            <Trans>partners.PARTNERS</Trans>
          </motion.span>
        </TitlePrimary>
      </PartnersWrapper>
        <Marquee pauseOnHover={true}>
          <PartnersList
            style={{
              opacity: (isDesktop || isTablet) && opacityProgress,
              bottom: (isDesktop || isTablet) && BottomProgress,
            }}
          >
            {listPartners1.map((item: any, index: number) => (
              <PartnersItem
                key={index}
                className={theme === "light" ? "light" : ""}
              >
                {/* <img className="image-normal" src={theme === "light" ? item.imgLight : item.img} alt="" /> */}
                <img src={item.img} alt="" />
              </PartnersItem>
            ))}
          </PartnersList>
        </Marquee>
        <Marquee direction={'right'} pauseOnHover={true}>
          <PartnersList
            className="list-02"
            style={{
              opacity: (isDesktop || isTablet) && opacityProgress,
              bottom: (isDesktop || isTablet) && BottomProgress,
            }}
          >
            {listPartners2.map((item: any, index: number) => (
              <PartnersItem
                key={index}
                className={theme === "light" ? "light" : ""}
              >
                {/* <img className="image-normal" src={theme === "light" ? item.imgLight : item.img} alt="" /> */}
                <img src={item.img} alt="" />
              </PartnersItem>
            ))}
          </PartnersList>
        </Marquee>
    </PartnersContainer>
  );
};

const listPartners1 = [
  {
    img: img01,
  },
  {
    img: img02,
  },
  {
    img: img03,
  },
  {
    img: img04,
  },
  {
    img: img05,
  },
  {
    img: img06,
  },
  {
    img: img07,
  },
  {
    img: img08,
  },
  {
    img: img09,
  },
  {
    img: img10,
  },
  {
    img: img11,
  },
  {
    img: img12,
  },
];
const listPartners2 = [
  {
    img: img13
  },
  {
    img: img14
  },
  {
    img: img15
  },
  {
    img: img16
  },
  {
    img: img17
  },
  {
    img: img18
  },
  {
    img: img19
  },
  {
    img: img20
  },
  {
    img: img21
  },
  {
    img: img22
  },
]
export default Partners;
