import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FeaturesContainer,
  FeaturesContent,
  FeaturesContentListItem,
  FeaturesContentListText,
  FeaturesContentTitle,
  FeaturesImage,
  FeaturesItem,
  FeaturesList,
  FeaturesModal,
  FeaturesModalClose,
  FeaturesModalInner,
  FeaturesModalItem,
  FeaturesModalItemImg,
  FeaturesModalItemSlide,
  FeaturesModalItemSlideContent,
  FeaturesModalItemSlideInner,
  FeaturesModalItemText,
  FeaturesModalItemTitle,
  FeaturesModalWrapperButton,
  Featurestext,
  FeaturesWrapper,
} from "./styled";
import ButtonCommon from "../../../assets/Common/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { motion } from "framer-motion";
// Image
import featuresImg01 from "../../../assets/LandingNew/Features/features_img_01.jpeg";
import featuresImg02 from "../../../assets/LandingNew/Features/features_img_02.jpeg";
import featuresImg03 from "../../../assets/LandingNew/Features/features_img_03.jpeg";
import modalImg01 from "../../../assets/LandingNew/Features/modal_img_01.png";
import modalImg02 from "../../../assets/LandingNew/Features/modal_img_02.png";
import modalImg03 from "../../../assets/LandingNew/Features/modal_img_03.png";
import modalImg04 from "../../../assets/LandingNew/Features/modal_img_04.png";
import modalImg05 from "../../../assets/LandingNew/Features/modal_img_05.png";
import modalImg06 from "../../../assets/LandingNew/Features/modal_img_06.png";
import modalImg07 from "../../../assets/LandingNew/Features/modal_img_07.png";
import modalImg08 from "../../../assets/LandingNew/Features/modal_img_08.png";
import modalImg09 from "../../../assets/LandingNew/Features/modal_img_09.png";
import modalClose from "../../../assets/LandingNew/Features/button_close.png";
import { useScroll, useTransform } from "framer-motion";
import { TitlePrimary } from "../../../assets/Common/TitleSection/styled";
import { ContextProviderWrapper } from "../../../components/Context";
import { Trans } from "react-i18next";

const FeaturesNew = () => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const backgroundPosition = useTransform(
    scrollYProgress,
    [0, 0.5],
    ["100%", "0%"]
  );
  const leftProgress = useTransform(scrollYProgress, [0, 0.5], [100, 0]);
  const leftProgress2 = useTransform(scrollYProgress, [0, 0.5], [200, 0]);
  return (
    <FeaturesContainer ref={ref} className={theme === "light" ? "light" : ""}>
      <FeaturesWrapper>
        {/* <TitleSection title={dataTitle}/> */}
        <TitlePrimary className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
          <motion.span
            style={{
              backgroundPositionX:
                (isDesktop || isTablet) && backgroundPosition,
              left: (isDesktop || isTablet) && leftProgress,
            }}
          >
            <Trans>features.key</Trans>
          </motion.span>
          <motion.span
            style={{
              backgroundPositionX:
                (isDesktop || isTablet) && backgroundPosition,
              left: (isDesktop || isTablet) && leftProgress2,
            }}
          >
            <Trans>features.features</Trans>
          </motion.span>
        </TitlePrimary>
        <Featurestext>
          <Trans>
            features.Innovative features that unlock the full potential of
            decentralized finance on the TON blockchain
          </Trans>
        </Featurestext>
        <FeaturesList>
          {dataFeatures.map((items: any, index: number) => (
            <Item index={index} items={items} />
          ))}
        </FeaturesList>
      </FeaturesWrapper>
    </FeaturesContainer>
  );
};

const dataModal = [
  {
    img: modalImg01,
    title: "features.Swap",
    text: "features.Swiftly swap token at best rate and lowest fee",
    textButton: "features.Swap",
  },
  {
    img: modalImg02,
    title: "features.Add Liquidity Pool",
    text: "features.Earn rewards by providing liquidity",
    textButton: "features.Swap",
  },
  {
    img: modalImg03,
    title: "features.Staking & Farming",
    text: "features.High APR APY rewards are awaiting you",
    textButton: "features.Coming soon",
  },
];
const dataModal2 = [
  {
    img: modalImg04,
    title: "features.DEX on Telegram",
    text: "features.Experience all DEX features directly on Telegram",
    textButton: "features.Coming soon",
  },
  {
    img: modalImg05,
    title: "features.trading Bot",
    text: "features.Snipe trade & maximize your profits",
    textButton: "features.Swap",
  },
  {
    img: modalImg06,
    title: "features.Airdrop Bot",
    text: "features.Control all about Airdrops in your hands",
    textButton: "features.Coming soon",
  },
];
const dataModal3 = [
  {
    img: modalImg07,
    title: "features.Fair-launch",
    text: "features.Fair token launch for equal opportunity",
    textButton: "features.Coming soon",
  },
  {
    img: modalImg08,
    title: "features.Pre-sale",
    text: "features.No commitment required, easily buy tokens from your favorite projects.",
    textButton: "features.Swap",
  },
  {
    img: modalImg09,
    title: "features.ICO & IDO",
    text: "features.Invest in innovation and potential project on our Launchpad",
    textButton: "features.Coming soon",
  },
];

const dataFeatures = [
  {
    id: 1,
    img: featuresImg01,
    title: "features.AMM",
    text: "",
    listText: [
      {
        text: "features.Low to zero slippage",
      },
      {
        text: "features.Complete transaction within 3 seconds",
      },
    ],
  },
  {
    id: 2,
    img: featuresImg02,
    title: `features.KIbble on telegram`,
    listText: [
      {
        text: "features.Gateway to the DeFi Hub from your Telegram",
      },
    ],
    isReverst: true,
  },
  {
    id: 3,
    img: featuresImg03,
    title: "features.Launchpad",
    listText: [
      {
        text: "features.Exclusive support for the launch of base-TON projects",
      },
    ],
  },
];

export default FeaturesNew;

const Item = ({ index, items }: any) => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const [showModal, setShowModal] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const rotateLeftProgress = useTransform(scrollYProgress, [0.5, 1], [-30, 0]);
  const rotateRightProgress = useTransform(scrollYProgress, [0.5, 1], [30, 0]);
  const leftProgress = useTransform(scrollYProgress, [0.5, 1], [-150, 0]);
  const rightProgress = useTransform(scrollYProgress, [0.5, 1], [150, 0]);
  const opacityProgress = useTransform(scrollYProgress, [0.5, 1], [0, 1]);
  const BottomProgress = useTransform(scrollYProgress, [0.5, 1], [-150, 0]);
  const topProgress = useTransform(scrollYProgress, [0.5, 1], [150, 0]);

  const handleClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [showModal]);

  return (
    <FeaturesItem
      ref={ref}
      key={index}
      className={items.isReverst && "is-reverst"}
    >
      <FeaturesImage
        style={{
          rotate:
            items.id === 1 || items.id === 3
              ? (isDesktop || isTablet) && rotateLeftProgress
              : (isDesktop || isTablet) && rotateRightProgress,
          left:
            items.id === 1 || items.id === 3
              ? (isDesktop || isTablet) && leftProgress
              : (isDesktop || isTablet) && rightProgress,
          top: (isDesktop || isTablet) && topProgress,
        }}
      >
        <img src={items.img} alt="" />
      </FeaturesImage>
      <FeaturesContent
        style={{
          opacity: (isDesktop || isTablet) && opacityProgress,
          bottom: (isDesktop || isTablet) && BottomProgress,
        }}
      >
        <FeaturesContentTitle className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
          <Trans>{items.title}</Trans>
        </FeaturesContentTitle>
        <FeaturesContentListText
          className={items.listText.length >= 2 ? "is-list" : ""}
        >
          {items.listText.map((item: any) => (
            <FeaturesContentListItem
              className={theme === "light" ? "light" : ""}
            >
              <Trans>{item.text}</Trans>
            </FeaturesContentListItem>
          ))}
        </FeaturesContentListText>
        <FeaturesModalWrapperButton onClick={() => handleClick()}>
          <ButtonCommon
            title="features.Show more"
            className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}
          />
        </FeaturesModalWrapperButton>
      </FeaturesContent>
      <FeaturesModal
        style={{ transform: showModal ? "translateY(0)" : "translateY(120%)" }}
        className={theme === "light" ? "light" : ""}
      >
        <FeaturesModalInner>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: isDesktop ? 121 : 100,
              modifier: 1,
              slideShadows: theme === "light" ? false : true,
            }}
            pagination={true}
            modules={[EffectCoverflow]}
            className="mySwiper"
            initialSlide={1}
          >
            <FeaturesModalItem>
              {items.id === 1 ? (
                <>
                  {dataModal.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <FeaturesModalItemSlide>
                        <FeaturesModalItemImg>
                          <img src={item.img} alt="" />
                        </FeaturesModalItemImg>
                        <FeaturesModalItemSlideContent>
                          <FeaturesModalItemSlideInner>
                            <FeaturesModalItemTitle
                              className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}
                            >
                              <Trans>{item.title}</Trans>
                            </FeaturesModalItemTitle>
                            <FeaturesModalItemText
                              className={theme === "light" ? "light" : ""}
                            >
                              <Trans>{item.text}</Trans>
                            </FeaturesModalItemText>
                          </FeaturesModalItemSlideInner>
                          <FeaturesModalWrapperButton></FeaturesModalWrapperButton>
                          {/* <ButtonCommon title={item.textButton} /> */}
                        </FeaturesModalItemSlideContent>
                      </FeaturesModalItemSlide>
                    </SwiperSlide>
                  ))}
                </>
              ) : items.id === 2 ? (
                <>
                  {dataModal2.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <FeaturesModalItemSlide>
                        <FeaturesModalItemImg>
                          <img src={item.img} alt="" />
                        </FeaturesModalItemImg>
                        <FeaturesModalItemSlideContent>
                          <FeaturesModalItemSlideInner>
                            <FeaturesModalItemTitle
                              className={theme === "light" ? "light" : ""}
                            >
                              <Trans>{item.title}</Trans>
                            </FeaturesModalItemTitle>
                            <FeaturesModalItemText
                              className={theme === "light" ? "light" : ""}
                            >
                              <Trans>{item.text}</Trans>
                            </FeaturesModalItemText>
                          </FeaturesModalItemSlideInner>
                          <FeaturesModalWrapperButton></FeaturesModalWrapperButton>
                          {/* <ButtonCommon title={item.textButton} /> */}
                        </FeaturesModalItemSlideContent>
                      </FeaturesModalItemSlide>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  {dataModal3.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <FeaturesModalItemSlide>
                        <FeaturesModalItemImg>
                          <img src={item.img} alt="" />
                        </FeaturesModalItemImg>
                        <FeaturesModalItemSlideContent>
                          <FeaturesModalItemSlideInner>
                            <FeaturesModalItemTitle
                              className={theme === "light" ? "light" : ""}
                            >
                              <Trans>{item.title}</Trans>
                            </FeaturesModalItemTitle>
                            <FeaturesModalItemText
                              className={theme === "light" ? "light" : ""}
                            >
                              <Trans>{item.text}</Trans>
                            </FeaturesModalItemText>
                          </FeaturesModalItemSlideInner>
                          <FeaturesModalWrapperButton></FeaturesModalWrapperButton>
                          {/* <ButtonCommon title={item.textButton} /> */}
                        </FeaturesModalItemSlideContent>
                      </FeaturesModalItemSlide>
                    </SwiperSlide>
                  ))}
                </>
              )}
            </FeaturesModalItem>
          </Swiper>
        </FeaturesModalInner>
        <FeaturesModalClose onClick={handleCloseModal}>
          <img src={modalClose} alt="" />
        </FeaturesModalClose>
      </FeaturesModal>
    </FeaturesItem>
  );
};
