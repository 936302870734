import styled from "styled-components";

export const AppContainer = styled.div<{ pathname: any }>`
  min-height: 100vh;
  /* background: ${({ pathname }) =>
    pathname ? "linear-gradient(180deg, #10012A 0%, #0B0122 100%)" : "#000"};
  overflow: hidden; */
  background-color: #141518;
`;

export const LoadingPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  transition: opacity .5s ease;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hidden-loading {
    opacity: 0;
    pointer-events: none;
  }
  video {
    width: 50%;
  }
  @media screen and (max-width: 767px) {
    display: none;
    background-color:unset;
  }
`