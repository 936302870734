import styled from "styled-components";
// Image
import icnCheck from "../../../assets/LandingNew/Features/features_icn_check.png";
import { ButtonPrimary } from "../../../assets/Common/Button/styled";
import { motion } from "framer-motion";
import mouses from "../../../assets/LandingNew/Features/mouse.png";

export const FeaturesContainer = styled.div`
  padding-top: 100px;
  background-color: #141518;
  &.light {
    background-color: #F7F8FF;
  }
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
`;
export const FeaturesWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 8vw;
  @media screen and (max-width: 767px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;
export const Featurestext = styled.p`
  font-size: 1.38889vw;
  line-height: 1.6vw;
  max-width: 23vw;
  margin-bottom: 100px;
  position: relative;
  font-weight: 400;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 26px;
    max-width: unset;
  }
`;
export const FeaturesList = styled.div``;
export const FeaturesContent = styled(motion.div)`
  margin-left: 100px;
  position: relative;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`;
export const FeaturesImage = styled(motion.figure)`
  max-width: 48vw;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    border-radius: 3vw;
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    margin-bottom: 20px;
    img {
      border-radius: 6vw;
    }
  }
`;
export const FeaturesItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 100px;
  }
  &.is-reverst {
    justify-content: flex-end;
    ${FeaturesContent} {
      order: 1;
      margin-left: 0;
      margin-right: 100px;
    }
    ${FeaturesImage} {
      order: 2;
    }
  }
  @media screen and (max-width: 767px) {
    display: block;
    &:not(:last-child) {
      margin-bottom: 50px;
    }
    &.is-reverst {
      ${FeaturesContent} {
        margin-right: 0;
      }
    }
  }
`;
export const FeaturesContentTitle = styled.h3`
  color: #b5ebff;
  font-size: 5.555555vw;
  font-family: Dirtyline;
  text-transform: lowercase;
  line-height: 5vw;
  &.light {
    color: #43424A;
  }
  @media (min-width: 767px) and (max-width: 1167px) {
    font-size: 50px;
    line-height: 1;
  }

  @media screen and (max-width: 767px) {
    font-size: 50px;
    line-height: 1;
  }
`;
export const FeaturesContentListItem = styled.li`
  font-size: 1.11111vw;
  color: #edfaff;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  &.light {
    color: #43424A;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;
export const FeaturesContentListText = styled.ul`
  margin-bottom: 2vw;
  &.is-list {
    li {
      padding-left: 20px;
      text-indent: -20px;
      &:before {
        display: inline-block;
        margin-right: 0.5vw;
        content: "";
        background: url(${icnCheck}) center / contain no-repeat;
        width: 0.97223vw;
        height: 0.97223vw;
        position: relative;
        top: 2px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.is-list {
      li {
        &:before {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
  }
`;

//modal
export const FeaturesModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
  transform: translateY(120%);
  transition: 0.26s ease;
  .swiper-slide {
    max-width: 692px;
    /* width: 100%; */
  }
  &.light {
    background-color: #eeeef0;
  }
  @media (min-width: 768px) and (max-width: 1450px) {
    .swiper-slide {
      max-width: 440px;
    }
  }
  @media screen and (max-width: 767px) {
    .swiper-slide {
      width: 80%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
`;
export const FeaturesModalInner = styled.div`
  .swiper-wrapper {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 767px) {
    .swiper-wrapper {
      min-height: auto;
      align-items: unset;
      justify-content: unset;
    }
  }
`;
export const FeaturesModalWrapperButton = styled.div`
  display: inline-block;
`;
export const FeaturesModalItem = styled.div``;
export const FeaturesModalItemSlide = styled.div`
  cursor: url(${mouses}), pointer;
`;
export const FeaturesModalItemSlideContent = styled.div`
  position: relative;
  width: 80%;
  margin: -27px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const FeaturesModalItemSlideInner = styled.div`
  position: relative;
  max-width: 74%;
  @media screen and (max-width: 767px) {
    max-width: unset;
  }
`;
export const FeaturesModalItemImg = styled.figure`
  img {
    border-radius: 33px;
    width: 100%;
  }
`;
export const FeaturesModalItemTitle = styled.h3`
  color: #edfaff;
  font-size: 72px;
  line-height: 0.8;
  margin-bottom: 10px;
  font-family: Dirtyline;
  text-transform: lowercase;
  &.light {
    color: #6E6E7C;
  }
  @media (min-width: 768px) and (max-width: 1450px) {
    font-size: 35px;
  }
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
`;
export const FeaturesModalItemText = styled.p`
  color: #edfaff;
  font-size: 14px;
  &.light {
    color: #6E6E7C;
  }
`;
export const FeaturesModalClose = styled.button`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  background-color: unset;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  img {
    width: 100%;
  }
`;
