import React, { useContext, useRef } from "react";
import {
  ChooseItem,
  ChooseItemArrow,
  ChooseItemContent,
  ChooseItemInner,
  ChooseItemText,
  ChooseItemTitle,
  ChooseList,
  ChooseNewContainer,
  ChooseNewWrapper,
  ChooseNewWrapperTitle,
} from "./styled";
import { motion } from "framer-motion";
// Image
import chooseArrow from "../../../assets/LandingNew/Choose/choose_icn_arrow.png";
import { useScroll, useTransform } from "framer-motion";
import { TitlePrimary } from "../../../assets/Common/TitleSection/styled";
import { ContextProviderWrapper } from "../../../components/Context";
import { Trans } from "react-i18next";

const ChooseNew = () => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const backgroundPosition = useTransform(
    scrollYProgress,
    [0, 0.5],
    ["100%", "0%"]
  );
  const leftProgress = useTransform(scrollYProgress, [0, 0.5], [200, 0]);
  const RightProgress = useTransform(scrollYProgress, [0, 0.5], [200, 0]);
  return (
    <ChooseNewContainer ref={ref}>
      <ChooseNewWrapper>
        {/* <TitleSection title={dataTitle} /> */}
        <ChooseNewWrapperTitle>
          <TitlePrimary className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
            <motion.span
              style={{
                backgroundPositionX:
                  (isDesktop || isTablet) && backgroundPosition,
                left: (isDesktop || isTablet) && leftProgress,
              }}
            >
              <Trans>choose.WHY</Trans>
            </motion.span>
            <motion.span
              style={{
                backgroundPositionX:
                  (isDesktop || isTablet) && backgroundPosition,
                right: (isDesktop || isTablet) && RightProgress,
              }}
            >
              <Trans>choose.CHOOSE US?</Trans>
            </motion.span>
          </TitlePrimary>
        </ChooseNewWrapperTitle>
        <ChooseList>
          {dataChoose.map((item: any, index: number) => (
            <Item index={index} item={item} />
          ))}
        </ChooseList>
      </ChooseNewWrapper>
    </ChooseNewContainer>
  );
};

const dataChoose = [
  {
    id: 1,
    title: "choose.Maximize Your DeFi Journey",
    text: "choose.Optimize your trading and benefits with our AMM DEX features",
    isFull: true,
  },
  {
    id: 2,
    title: "choose.Onboarding the TON Community",
    text: "choose.Enjoying convenient access through Telegram bots and get into the DeFi world",
  },
  {
    id: 3,
    title: "choose.Priorities become the first Holders",
    text: "choose.Easily participate in token sales and purchases new token on TON blockchain",
  },
];

export default ChooseNew;

const Item = ({ index, item }: any) => {
  const { isDesktop, theme, isTablet } = useContext(ContextProviderWrapper)!;
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const BottomProgress = useTransform(scrollYProgress, [0, 1], [-300, 0]);
  const LeftProgress = useTransform(scrollYProgress, [0, 1], [-400, 0]);
  const RightProgress = useTransform(scrollYProgress, [0, 1], [-400, 0]);
  return (
    <ChooseItem
      ref={ref}
      key={index}
      className={item.isFull && "is-full"}
      style={{
        bottom: item.id === 1 ? (isDesktop || isTablet) && BottomProgress : "",
        left: item.id === 2 ? (isDesktop || isTablet) && LeftProgress : "",
        right: item.id === 3 ? (isDesktop || isTablet) && RightProgress : "",
      }}
    >
      <ChooseItemInner ref={ref}>
        <ChooseItemContent>
          <ChooseItemTitle className={theme === "light" ? "light" : ""}>
            <Trans>{item.title}</Trans>
          </ChooseItemTitle>
          <ChooseItemText className={theme === "light" ? "light" : ""}>
            <Trans>{item.text}</Trans>
          </ChooseItemText>
        </ChooseItemContent>
        <ChooseItemArrow>
          <img src={chooseArrow} alt="" />
        </ChooseItemArrow>
      </ChooseItemInner>
    </ChooseItem>
  );
};
