import { motion } from "framer-motion";
import styled from "styled-components";

export const BlockVideoConainer = styled.div`
  position: relative;
  padding-top: 25px;
  padding-bottom: 100px;
  background-color: #141518;
  z-index: 1;
  &.light {
    background-color: #eeeef0;
  }
`;
export const BlockVideoWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 8vw;
  @media screen and (max-width: 767.98px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;
export const BlockVideoWrapperInner = styled(motion.div)`
  /* height: 95vh; */
  margin: 0 auto;
  transform-origin: top;
  transform: scale(0.2);
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 32px;
  }
  @media screen and (max-width: 767px) {
    transform: scale(1);
  }
`;
