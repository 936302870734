import styled from "styled-components";

export const TitlePrimary = styled.h2`
  font-family: Dirtyline;
  font-size: 9.7222vw;
  letter-spacing: 0.02em;
  display: inline-flex;
  flex-direction: column;
  span {
    text-transform: lowercase;
    background: linear-gradient(90deg, #e0eeee 50%, #252525 0);
    background-size: 200% 100%;
    background-position-x: 100%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    position: relative;
    &:nth-child(2) {
      margin-top: -2.5vw;
      display: inline-block;
    }
  }
  &.light {
    span {
      background: linear-gradient(90deg, #3f4144 50%, #dce1e1 0);
      background-size: 200% 100%;
      background-position-x: 100%;
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
  &.is-vi {
    line-height: 1.4;
  }
  @media screen and (max-width: 1023px) {
    font-size: 60px;
    span {
      background-position-x: 0%;
      &:nth-child(2) {
        margin-top: -10px;
      }
    }
    &.light {
      span {
        background-position-x: 0%;
      }
    }
  }
`;
