import React, { useContext, useRef, useState } from "react";
import {
  TeamContainer,
  TeamInfo,
  TeamItem,
  TeamItemArrow,
  TeamItemDetail,
  TeamItemImage,
  TeamItemPosition,
  TeamItemSocial,
  TeamItemText,
  TeamItemTitle,
  TeamSocial,
  Teamtext,
  TeamWrapper,
  TextSlide,
} from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Marquee from "react-fast-marquee";
import { Autoplay } from "swiper/modules";
import {
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from "framer-motion";
// Image
import teamImg01 from "../../../assets/LandingNew/Team/team_img_01.jpeg";
import teamImg02 from "../../../assets/LandingNew/Team/team_img_02.jpeg";
import teamImg03 from "../../../assets/LandingNew/Team/team_img_03.jpeg";
import teamImg04 from "../../../assets/LandingNew/Team/team_img_04.jpeg";
import teamImg05 from "../../../assets/LandingNew/Team/team_img_05.jpeg";
import social01 from "../../../assets/LandingNew/Team/social_01.png";
import social02 from "../../../assets/LandingNew/Team/social_02.png";
import arrow from "../../../assets/LandingNew/Team/icn_arrow_white.png";
import { TitlePrimary } from "../../../assets/Common/TitleSection/styled";
import { ContextProviderWrapper } from "../../../components/Context";
import { Trans } from "react-i18next";

const TeamNew = () => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const [playSlide, setPlaySide] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const backgroundPosition = useTransform(
    scrollYProgress,
    [0, 0.5],
    ["100%", "0%"]
  );
  const leftProgress = useTransform(scrollYProgress, [0, 0.5], [100, 0]);
  const leftProgress2 = useTransform(scrollYProgress, [0, 0.5], [200, 0]);
  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > 0.01) {
      setPlaySide(true);
    }
  });
  return (
    <TeamContainer ref={ref}>
      {playSlide && (
        <>
          <TeamWrapper>
            <TitlePrimary className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
              <motion.span
                style={{
                  backgroundPositionX:
                    (isDesktop || isTablet) && backgroundPosition,
                  left: (isDesktop || isTablet) && leftProgress,
                }}
              >
                <Trans>team.OUR</Trans>
              </motion.span>
              <motion.span
                style={{
                  backgroundPositionX:
                    (isDesktop || isTablet) && backgroundPosition,
                  left: (isDesktop || isTablet) && leftProgress2,
                }}
              >
                <Trans>team.TEAM</Trans>
              </motion.span>
            </TitlePrimary>
            <Teamtext>
              <Trans>
                team.Innovative features that unlock the full potential of
                decentralized finance on the TON blockchain
              </Trans>
            </Teamtext>
          </TeamWrapper>
          <TeamInfo>
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={40}
              className="mySwiper"
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay]}
              loop={true}
            >
              {dataTeam.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <TeamItem>
                    <TeamItemArrow>
                      <img src={arrow} alt="" />
                    </TeamItemArrow>
                    <TeamItemDetail
                      className={theme === "light" ? "light" : ""}
                    >
                      <TeamItemImage>
                        <img src={item.img} alt="" />
                      </TeamItemImage>
                      <TeamItemTitle
                        className={theme === "light" ? "light" : ""}
                      >
                        <Trans>{item.title}</Trans>
                      </TeamItemTitle>
                      <TeamItemPosition>
                        <Trans>{item.position}</Trans>
                      </TeamItemPosition>
                      <TeamItemText
                        className={theme === "light" ? "light" : ""}
                      >
                        <Trans>{item.text}</Trans>
                      </TeamItemText>
                      <TeamSocial>
                        <TeamItemSocial>
                          <a
                            href={item.linked}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={social01} alt="" />
                          </a>
                        </TeamItemSocial>
                        <TeamItemSocial>
                          <a
                            href={item.twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={social02} alt="" />
                          </a>
                        </TeamItemSocial>
                      </TeamSocial>
                    </TeamItemDetail>
                  </TeamItem>
                </SwiperSlide>
              ))}
            </Swiper>
          </TeamInfo>
        </>
      )}
      <Marquee>
        <TextSlide className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
          <Trans>team.YOUR PREMIER DEFI HUB ON TON</Trans>
        </TextSlide>
      </Marquee>
      <Marquee direction="right" className="text-bottom">
        <TextSlide className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
          <Trans>team.YOUR PREMIER DEFI HUB ON TON</Trans>
        </TextSlide>
      </Marquee>
    </TeamContainer>
  );
};

const dataTeam = [
  {
    img: teamImg01,
    title: "Smith John",
    twitter: "https://twitter.com/longpc0209",
    linked: "https://www.linkedin.com/in/longliu0209/",
    position: "team.CEO",
    text: "team.With over a decade of experience in the Blockchain and Web3.0, he's now Technology Orbit's CEO and Co-Founder BSCS Launchpad. He focuses on developing DeFi, NFT, and Metaverse products and applying AI technology in Web3.0",
  },
  {
    img: teamImg02,
    title: "Zhiqi Ou",
    twitter: "#",
    linked: "https://www.linkedin.com/in/ouzhiqi/",
    position: "team.CTO",
    text: "team.Zhiqi launched his first blockchain startup in 2016 and has developed projects based on a range of Web3 technology including the EVM, Hyperledger Fabric, and Decentralized Identifiers.",
  },
  {
    img: teamImg03,
    title: "Michael Krützfeldt",
    twitter: "https://twitter.com/0xend2002",
    linked: "https://www.linkedin.com/in/michael-kr%C3%BCtzfeldt-2a299518b/",
    position: "team.CMO",
    text: "team.Co-founder of Ankhlabs one of the leading Web3 Marketing agencies and has worked with over 300 projects including top brands like Bitget, BIC, Portalcoin, Seedify,..",
  },
  // {
  //   img: teamImg04,
  //   title: "Artem Sharikov",
  //   twitter: "#",
  //   linked: "https://www.linkedin.com/in/artem-sharikov-007658241/",
  //   position: "BDM",
  //   text: "team.With over 8 years of experience in the crypto market, he has spearheaded business development initiatives at renowned exchanges including Huobi, MEXC, and OKX",
  // },
  {
    img: teamImg05,
    title: "Kengan",
    position: "CBDO",
    twitter: "#",
    linked: "#",
    text: "team.Already have more than 4 years in the crypto market. A core member in projects that are invented by Binance labs, Playventrues, DeFiance Capital",
  },
];

const dataTitle = [
  {
    title: "OUR",
  },
  {
    img: teamImg05,
    title: "Kengan",
    position: "team.BD",
    twitter: "#",
    linked: "#",
    text: "team.Already have more than 4 years in the crypto market. A core member in projects that are invented by Binance labs, Playventrues, DeFiance Capital",
  },
];

export default TeamNew;
