import { Link } from "react-router-dom";
import styled from "styled-components";
import { InputContainer } from "../Input/styled";
import { ButtonPrimary } from "../../assets/Common/Button/styled";
import { motion } from "framer-motion";

export const FooterContainer = styled.div`
  overflow: hidden;
  padding: 0 100px 1.7vw;
  @media screen and (min-width: 768px) and (max-width: 1250px) {
    padding: 50px 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 30px;
  }
`;
export const FooterWrapper = styled.div`
  max-width: 79.2vw;
  margin: 0 auto;
  display: flex;
  /* align-items: flex-end; */
  gap: 40px;
  @media screen and (max-width: 1023px) {
    display: block;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1200px;
  }
`;
export const FooterListFunction = styled.ul`
  display: flex;
  align-items: center;
  gap: 0.3vw;
  margin-bottom: 2vw;
  flex-wrap: wrap;
  position: relative;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    gap: 10px;
  }
`;
export const FooterItemFunction = styled.li`
  a {
    padding: 4px 5px 0px;
    width: 7.5vw;
    height: 2.7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Neutral-1000, #000);
    font-family: Dirtyline;
    font-size: 1.166666vw;
    text-transform: lowercase;
    background-color: #f7f7f8;
    border-radius: 51px;
    &.light {
      background-color: #d9d9de;
      color: #000;
    }
  }
  &.is-font-size {
    a {
      font-size: .9vw;
    }
  }
  @media screen and (max-width: 768px) {
    a {
      min-width: 100px;
      height: 25px;
      font-size: 16px;
    }
    &.is-font-size {
    a {
      font-size: 12px;
    }
  }
  }
`;
export const FooterMain = styled.div`
  p {
    position: relative;
    color: #d9d9de;
    font-size: 1vw;
    line-height: 2.4vh;
    letter-spacing: 0.28px;
    max-width: 21vw;
    margin-bottom: 10px;
    &.light {
      color: #43424a;
    }
  }
  ${ButtonPrimary} {
    p {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 1023px) {
    max-width: unset;
    position: relative;
    z-index: 1;
    p {
      max-width: unset;
      font-size: 14px;
      line-height: 22px;
    }
    ${ButtonPrimary} {
      p {
        font-size: 16px;
      }
    }
  }
`;
export const FooterLogo = styled(Link)`
  display: block;
  width: 21.042vw;
  position: relative;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 200px;
  }
`;
export const FooterNav = styled.ul`
  display: flex;
  align-items: flex-start;
  gap: 60px;
  margin-left: 1vw;
  & > li {
    & > h2 {
      margin-bottom: 15px;
      color: #d9d9de;
      font-size: 0.97222vw;
      line-height: 20px;
      letter-spacing: 0.4px;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    & > ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 0.65vh;
        }
        a {
          display: inline-block;
          color: #92929e;
          font-size: 0.90278vw;
          letter-spacing: 0.28px;
          white-space: nowrap;
          transition: color 0.26s ease;
          &:hover {
            color: #007af5;
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1250px) {
    gap: 40px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > li {
      & > ul {
        & > li {
          a {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
    & > li {
      h2 {
        font-size: 14px;
      }
      & > ul {
        & > li {
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          a {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
`;
export const CommunityList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 15px;
  & > li {
    a {
      display: block;
    }
  }
`;
export const FooterSocial = styled.ul`
  max-width: 37.5vw;
  width: 100%;
  margin-left: auto;
  position: relative;
  & > li {
    position: absolute;
    width: max-content;
    margin: 0 auto;
    text-align: center;
    border-radius: 41px;
    color: #fff;
    transition: all 0.5s ease;
    &:hover {
      background: #007af5;
    }
    & > p {
      color: #fff;
      font-family: DirtyLine;
      font-size: 1.666666vw;
      font-weight: 400;
      text-transform: lowercase;
      & > a {
        color: #fff;
        display: inline-block;
        padding: 0.9vw 1.5vw 0.5vw;
      }
    }
    &:nth-child(1) {
      background-color: #1eb0ff;
      bottom: 400px;
      right: 23vw;
      transform: rotate(9deg);
      &.is-animation {
        animation: drop 3s cubic-bezier(0, 0, 0.35, 1) forwards;
      }
      @keyframes drop {
        0% {
          bottom: 400px;
          animation-timing-function: ease-in;
        }
        40% {
          bottom: 300px;
          animation-timing-function: ease-in;
        }
        65% {
          bottom: 200px;
          animation-timing-function: ease-in;
        }
        82% {
          bottom: 100px;
          animation-timing-function: ease-in;
        }
        92% {
          bottom: 20px;
          animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87%,
        97%,
        100% {
          bottom: 0;
          animation-timing-function: ease-out;
        }
      }
    }
    &:nth-child(2) {
      background-color: #000;
      transform: rotate(-4deg);
      right: 16.5vw;
      bottom: 20vw;
      &.is-animation {
        animation: drop2 3s cubic-bezier(0, 0, 0.35, 1) forwards;
      }
      @keyframes drop2 {
        0% {
          bottom: 20vw;
          animation-timing-function: ease-in;
        }
        40% {
          bottom: 6vw;
          animation-timing-function: ease-in;
        }
        65% {
          bottom: 5vw;
          animation-timing-function: ease-in;
        }
        82% {
          bottom: 4vw;
          animation-timing-function: ease-in;
        }
        92% {
          bottom: 3.5vw;
          animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87%,
        97%,
        100% {
          bottom: 3vw;
          animation-timing-function: ease-out;
        }
      }
    }
    &:nth-child(3) {
      background-color: #5865f2;
      right: 10.4vw;
      bottom: 20vw;
      transform: rotate(6deg);
      &.is-animation {
        animation: drop3 3s cubic-bezier(0, 0, 0.35, 1) forwards;
      }
      @keyframes drop3 {
        0% {
          bottom: 20vw;
          animation-timing-function: ease-in;
        }
        40% {
          bottom: 3vw;
          animation-timing-function: ease-in;
        }
        65% {
          bottom: 2vw;
          animation-timing-function: ease-in;
        }
        82% {
          bottom: 1vw;
          animation-timing-function: ease-in;
        }
        92% {
          bottom: 0.5vw;
          animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87%,
        97%,
        100% {
          bottom: 0;
          animation-timing-function: ease-out;
        }
      }
    }
    &:nth-child(4) {
      background-color: #f7f7f8;
      p,
      a {
        color: #000;
      }
      transform: rotate(24deg);
      right: 9.8vw;
      bottom: 20vw;
      &.is-animation {
        animation: drop4 3s cubic-bezier(0, 0, 0.35, 1) forwards;
      }
      @keyframes drop4 {
        0% {
          bottom: 20vw;
          animation-timing-function: ease-in;
        }
        40% {
          bottom: 8vw;
          animation-timing-function: ease-in;
        }
        65% {
          bottom: 7vw;
          animation-timing-function: ease-in;
        }
        82% {
          bottom: 6vw;
          animation-timing-function: ease-in;
        }
        92% {
          bottom: 5vw;
          animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87%,
        97%,
        100% {
          bottom: 4vw;
          animation-timing-function: ease-out;
        }
      }
    }
    &:nth-child(5) {
      background-color: #d83b3b;
      transform: rotate(-9deg);
      right: 0;
      bottom: 20vw;
      &.is-animation {
        animation: drop5 3s cubic-bezier(0, 0, 0.35, 1) forwards;
      }
      @keyframes drop5 {
        0% {
          bottom: 20vw;
          animation-timing-function: ease-in;
        }
        40% {
          bottom: 6vw;
          animation-timing-function: ease-in;
        }
        65% {
          bottom: 4vw;
          animation-timing-function: ease-in;
        }
        82% {
          bottom: 2vw;
          animation-timing-function: ease-in;
        }
        92% {
          bottom: 1vw;
          animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87%,
        97%,
        100% {
          bottom: 0;
          animation-timing-function: ease-out;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > li {
      & > p {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    margin-left: 0;
    margin-top: -90px;
    max-width: unset;
    width: 100%;
    min-height: 200px;
    & > li {
      animation: unset;
      width: 25%;
      height: 2.1rem;
      min-width: 89px;
      > p {
        font-size: 16px;
        height: 100%;
        a {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
      &:nth-child(1) {
        bottom: 0;
        left: 10%;
        right: unset;
      }
      &:nth-child(2) {
        bottom: 34px;
        transform: translateY(0) rotate(-3deg);
        left: 27%;
        right: unset;
      }
      &:nth-child(3) {
        bottom: -8px;
        left: 46%;
        right: unset;
      }
      &:nth-child(4) {
        bottom: 38px;
        left: 55%;
        right: unset;
      }
      &:nth-child(5) {
        right: -8px;
        bottom: 0;
      }
    }
  }
`;
export const FooterSubscribe = styled.div`
  position: relative;
`;
export const SubscribeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  max-width: 79.2vw;
  margin: 0 auto;
  ${ButtonPrimary} {
    padding-top: 9px;
    padding-bottom: 9px;
    &.is-vi {
      padding-top: 12px;
      padding-bottom: 6px;
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    max-width: unset;
  }
`;
export const SubscribeForm = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  & > p {
    color: #6e6e7c;
    font-size: 0.97222vw;
    margin-right: 56px;
  }
  ${InputContainer} {
    width: 100%;
    border-radius: 11px;
    border: 1px solid #4d4d57;
    box-shadow: 0px -3px 0px 0px rgba(56, 56, 56, 0.25) inset;
    .ant-input-affix-wrapper {
      padding: 8px 10px;
      font-size: 0.97222vw;
    }
    .ant-input-prefix {
      width: 1.181vw;
      height: 1.1111111vw;
      position: relative;
      top: 0.3vw;
      img {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    & > p {
      font-size: 13px;
    }
    ${InputContainer} {
      margin-left: 10px;
      .ant-input-affix-wrapper {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }

  @media screen and (max-width: 845px) {
    .sm {
      display: none;
    }
    width: 100%;
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    gap: 20px;
    display: block;
    & > p {
      font-size: 14px;
      line-height: 19.2px;
    }
    ${InputContainer} {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px;
      .ant-input-prefix {
        width: 17px;
        height: 16px;
        top: 0px;
      }
    }
    & > p {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  &.light {
    ${InputContainer} {
      border: none;
    }
  }
`;
export const ContentInput = styled.div`
  position: relative;
  width: calc(100% - 11vw);
  margin-right: 15px;
  .title-input {
    position: absolute;
    font-size: 0.83333vw;
    color: #6e6e7c;
    top: -0.9vh;
    left: 1vw;
    font-family: SF;
    display: inline-block;
    background-color: #141518;
    padding: 0 3px;
    &.light {
      background-color: #eeeef0;
      z-index: 2;
    }
  }
  &.light {
    .ant-input-affix-wrapper {
      background-color: #eeeef0;
      border: 1px solid var(--Neutral-400, #92929E);
      box-shadow: 0px -3px 0px 0px rgba(56, 56, 56, 0.25) inset;
      &:focus-within {
        border: 1px solid var(--Neutral-400, #92929E) !important;
        box-shadow: 0px -3px 0px 0px rgba(56, 56, 56, 0.25) inset !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .title-input {
      font-size: 12px;
      left: 21px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    .title-input {
      left: 11px;
      font-size: 12px;
      top: -6px;
    }
  }
`;

export const FooterLast = styled.div`
  background-color: #007af5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(100vw - 2%);
  margin: 0 auto;
  border-radius: 28px;
  padding: 1vw 0;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    padding: 15px 0;
    width: calc(100vw - 14%);
  }
`;
export const FooterLastItem = styled(motion.span)`
  img {
    width: 100%;
  }
  &:not(:first-child),
  &:not(:last-child) {
    margin-right: 2.4vw;
  }
  &:nth-child(1) {
    width: 25.278vw;
  }
  &:nth-child(2) {
    width: 10.1389vw;
  }
  &:nth-child(3) {
    width: 3.334vw;
  }
  &:nth-child(4) {
    width: 10.07vw;
  }
  &:nth-child(5) {
    width: 10.07vw;
  }
  &:nth-child(6) {
    width: 7.57vw;
  }
  &:nth-child(7) {
    width: 11.806vw;
    margin-right: 0;
  }
  @media screen and (max-width: 768px) {
    width: auto !important;
    height: 50px;
    img {
      width: auto;
      height: 100%;
    }
  }
`;
