import React, { useContext } from "react";
import {
  ButtonKeyvisual,
  KeyvisualNewContainer,
  KeyvisualText,
  KeyvisualTitle,
  LandingNewWrapper,
  ListButton,
} from "./styled";

// Image
import keyvisualTitle from "../../../assets/LandingNew/Keyvisual/keyvisual_title.png";
import keyvisualTitle02 from "../../../assets/LandingNew/Keyvisual/keyvisual_title_02.png";
import buttonKeyvisual from "../../../assets/LandingNew/Keyvisual/button_keyvisual.png";
import BlockVideo from "../BlockVideo";
import ButtonCommon from "../../../assets/Common/Button";
import { ContextProviderWrapper } from "../../../components/Context";
import { Trans } from "react-i18next";

const KeyvisualNew = () => {
  const { theme } = useContext(ContextProviderWrapper)!;

  return (
    <>
      <KeyvisualNewContainer className={theme === "light" ? "light" : ""}>
        <LandingNewWrapper>
          <KeyvisualTitle>
            {theme === "light" ? (
              <img src={keyvisualTitle02} alt="" />
            ) : (
              <img src={keyvisualTitle} alt="" />
            )}
          </KeyvisualTitle>
          <KeyvisualText>
            <Trans>keyvisual.We are here to provide all you need for the best trading experience
            and optimal advances on TON blockchain</Trans>
          </KeyvisualText>
          <ListButton className={theme === "light" ? "light" : ""}>
            <ButtonCommon
              className={theme === "light" ? "light" : ""}
              link={"https://docsend.com/view/uau8pwnjb49v462u"}
              target
              title="keyvisual.LitePaper"
            />
            <ButtonCommon
              className={theme === "light" ? "light" : ""}
              link={"https://docs.kibble.exchange/"}
              target
              title="keyvisual.Whitepaper"
            />
          </ListButton>
        </LandingNewWrapper>
        <ButtonKeyvisual>
          <img src={buttonKeyvisual} alt="" />
        </ButtonKeyvisual>
      </KeyvisualNewContainer>
      <BlockVideo />
    </>
  );
};

export default KeyvisualNew;
