import styled from "styled-components";
// Immage
import roadmapImg01 from "../../../assets/LandingNew/Roadmap/roadmap_img_01.jpg";
import roadmapImg01Light from "../../../assets/LandingNew/Roadmap/roadmap_img_01_light.jpg";
import roadmapImg02 from "../../../assets/LandingNew/Roadmap/roadmap_img_02.jpg";
import roadmapImg02Light from "../../../assets/LandingNew/Roadmap/roadmap_img_02_light.jpg";
import roadmapImg03 from "../../../assets/LandingNew/Roadmap/roadmap_img_03.jpg";
import roadmapImg03Light from "../../../assets/LandingNew/Roadmap/roadmap_img_03_light.jpg";
import roadmapImg04 from "../../../assets/LandingNew/Roadmap/roadmap_img_04.jpg";
import roadmapImg04Light from "../../../assets/LandingNew/Roadmap/roadmap_img_04_light.jpg";
import arrow from "../../../assets/LandingNew/Roadmap/roadmap_arrow.png";
import { motion } from "framer-motion";

export const RoadmapContainer = styled.div`
  overflow: hidden;
  padding-top: 200px;
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;
export const RoadmapWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 8.3vw;
  @media (min-width: 767px) and (max-width: 1800px) {
    padding: 0 5vw;
  }
  @media screen and (max-width: 767px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;
export const RoadmapWrapperTitle = styled.div`
  text-align: center;
`;
export const RoadmapList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const RoadmapItemInner = styled.div`
  padding: 80px;
  @media (min-width: 767px) and (max-width: 1250px) {
    padding: 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
`;
export const RoadmapItem = styled(motion.li)`
  max-width: 44.43%;
  width: 100%;
  background: center / cover no-repeat;
  border-radius: 2.8vw;
  aspect-ratio: 575 / 425;
  position: relative;
  &:nth-child(1) {
    background-image: url(${roadmapImg01});
  }
  &:nth-child(2) {
    background-image: url(${roadmapImg02});
    ${RoadmapItemInner} {
      width: 44%;
      margin-left: auto;
    }
  }
  &:nth-child(3) {
    background-image: url(${roadmapImg03});
  }
  &:nth-child(4) {
    background-image: url(${roadmapImg04});
    ${RoadmapItemInner} {
      width: 35%;
      margin-left: auto;
    }
  }
  /* &:nth-child(2n + 2) {
    margin-left: auto;
  } */
  &:not(:last-chidl) {
    margin-bottom: 5px;
  }
  &.light {
    &:nth-child(1) {
      background-image: url(${roadmapImg01Light});
      h3,
      li {
        color: #6e6e7c;
      }
      li {
        &:before {
          background-color: #6e6e7c;
        }
      }
    }
    &:nth-child(2) {
      background-image: url(${roadmapImg02Light});
      h3,
      li {
        color: #6e6e7c;
      }
      li {
        &:before {
          background-color: #6e6e7c;
        }
      }
    }
    &:nth-child(3) {
      background-image: url(${roadmapImg03Light});
    }
    &:nth-child(4) {
      background-image: url(${roadmapImg04Light});
    }
  }
  @media (min-width: 767px) and (max-width: 1300px) {
    &:nth-child(2),
    &:nth-child(4) {
      ${RoadmapItemInner} {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    aspect-ratio: unset;
    width: 100%;
    max-width: unset;
    min-height: 250px;
    border-radius: 20px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:nth-child(2n + 2) {
      margin-left: unset;
    }
    &:nth-child(2),
    &:nth-child(4) {
      ${RoadmapItemInner} {
        width: 100%;
      }
    }
  }
`;

export const RoadmapItemTitle = styled.h3`
  color: var(--Neutral-50, #f7f7f8);
  font-family: Dirtyline;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
  margin-bottom: 1vw;
  span {
    font-family: DMMono-500;
    margin-left: 10px;
  }
  &.is-arrow {
    position: relative;
    padding-left: 3vw;
    &:before {
      position: absolute;
      content: "";
      background: url(${arrow}) center / contain no-repeat;
      width: 2.22222vw;
      height: 2.22222vw;
      left: 0;
      top: 50%;
      margin-top: -1.11111vw;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 28px;
    &.is-arrow {
      padding-left: 40px;
      &:before {
        width: 28px;
        height: 28px;
        margin-top: -14px;
      }
    }
  }
`;
export const RoadmapItemSubList = styled.ul``;
export const RoadmapItemSubListitem = styled.li`
  color: var(--Neutral-50, #f7f7f8);
  font-family: Syne;
  font-size: 1.11111vw;
  font-style: normal;
  font-weight: 400;
  position: relative;
  padding-left: 15px;
  line-height: 1.7vw;
  &:before {
    position: absolute;
    top: 0.7vw;
    left: 0;
    content: "";
    width: 5px;
    height: 5px;
    background-color: #fff;
    display: inline-block;
    margin-right: 10px;
  }
  @media (min-width: 767px) and (max-width: 1250px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 1.2;
    &:before {
      top: 8px;
    }
  }
`;
