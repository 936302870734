import React, { useContext, useRef } from "react";
import { WhoContainer, WhoContentText, WhoItem, WhoList, WhoText, WhoWrapper } from "./styled";
import { useScroll, useTransform } from "framer-motion";
import { motion } from 'framer-motion';
import { TitlePrimary } from "../../../assets/Common/TitleSection/styled";
import { ContextProviderWrapper } from "../../../components/Context";
import { Trans } from "react-i18next";

const WhoNew = () => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const backgroundPosition = useTransform(scrollYProgress, [0, .5], ["100%", "0%"])
  const leftProgress = useTransform(scrollYProgress, [0, .5], [100, 0])
  const leftProgress2 = useTransform(scrollYProgress, [0, .5], [200, 0])
  return (
    <WhoContainer ref={ref}>
      <WhoWrapper>
        <TitlePrimary className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
          <motion.span style={{
            backgroundPositionX: (isDesktop || isTablet) && backgroundPosition,
            left: (isDesktop || isTablet) && leftProgress
          }}><Trans>who.who</Trans></motion.span>
          <motion.span style={{
            backgroundPositionX: (isDesktop || isTablet) && backgroundPosition,
            left: (isDesktop || isTablet) && leftProgress2
          }}><Trans>who.we are</Trans></motion.span>
        </TitlePrimary>
        <WhoText className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
          <Trans>who.Kibble is the comprehensive DeFi Hub that provides all you need for
          the best trading experience and optimal advances on TON.</Trans>
        </WhoText>
        <WhoContentText>
          <WhoList>
            {dataWhow1.map((item: any, index: number) => (
              <Item index={index} item={item} />
            ))}
          </WhoList>
          <WhoList className="list-02">
            {dataWhow2.map((item: any, index: number) => (
              <Item index={index} item={item} />
            ))}
          </WhoList>
        </WhoContentText>
      </WhoWrapper>
    </WhoContainer>
  );
};

const dataWhow1 = [
  {
    id: 1,
    // number: "5000",
    number: "TBA",
    text: "LIQUIDITY POOL",
  },
  {
    id: 2,
    // number: "97M",
    number: "TBA",
    text: "TOTAL VALUE LOCKED",
  },

];
const dataWhow2 = [
  {
    id: 3,
    // number: "1,780K",
    number: "TBA",
    text: "Trading Volume",
  },
  {
    id: 4,
    // number: "1,320K",
    number: "TBA",
    text: "Transaction",
  },
];


export default WhoNew;

const Item = ({ index, item }: any) => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const leftProgress = useTransform(scrollYProgress, [0, 1], [-400, 0])
  const rightProgress = useTransform(scrollYProgress, [0, 1], [-400, 0])
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1])
  return (
    <WhoItem  key={index} ref={ref} style={{
      left: (item.id === 1 || item.id === 2) ? (isDesktop || isTablet) && leftProgress : '',
      right: (item.id === 3 || item.id === 4) ? (isDesktop || isTablet) && rightProgress : '',
      opacity: opacity
    }} className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
      <span className={(lng === 'ar' || lng === 'pt' || lng === 'ru') ? 'is-font-size' : ''}><Trans>{item.number}</Trans></span>
      <p><Trans>{item.text}</Trans></p>
    </WhoItem>
  )
}