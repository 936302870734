import React from 'react'
import { LandingNewContainer } from './styled'
import KeyvisualNew from './Keyvisual'
import FeaturesNew from './Features'
import ChooseNew from './Choose'
import TeamNew from './Team'
import WhoNew from './Who'
import RoadmapNew from './Roadmap'
import Partners from './Partners'

const LandingNew = () => {
  return (
    <LandingNewContainer>
      <KeyvisualNew />
      <FeaturesNew />
      <ChooseNew />
      <TeamNew />
      <WhoNew />
      <RoadmapNew />
      <Partners />
    </LandingNewContainer>
  )
}

export default LandingNew