import styled from "styled-components";

export const ButtonPrimary = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  flex-shrink: 0;
  padding: 12px 20px;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid #3A3A40;
  background: #43424a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 3px 0px 0px rgba(186, 186, 186, 0.33) inset;
  cursor: pointer;
  transition: all 0.15s linear;
  font-family: "Syne";
  &:hover {
    background-color: #007af5;
  }
  p {
    color: #fff;
    font-size: 0.97222vw;
    font-weight: 500;
  }
  img {
    margin-left: 10px;
  }
  &.light {
    background-color: #007af5;
    background: var(--Blue--Pri-700, #007af5);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 3px 0px 0px rgba(186, 186, 186, 0.33) inset;
    border: solid 2px transparent;
  }
  &.is-vi {
    padding-top: 12px;
    padding-bottom: 6px;
  }
  @media screen and (max-width: 768px) {
    padding: 11px 9px;
    p {
      font-size: 16px;
    }
    img {
      width: 16px !important;
    }
  }
`;
