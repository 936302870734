import React from "react";
import arrow from "../../../assets/Footer/arrow.svg";
import { ButtonPrimary } from "./styled";
import { Trans } from "react-i18next";

const ButtonCommon = ({ title, link, target, className }: any) => {
  return (
    <ButtonPrimary href={link && link} target={target && "_blank"} className={className && className}>
      <p><Trans>{title}</Trans></p>
      <img src={arrow} alt="icon" />
    </ButtonPrimary>
  );
};

export default ButtonCommon;
