import Footer from "./components/Footer";
import { AppContainer, LoadingPage } from "./styled";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider, ContextProviderWrapper } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect, useRef, useState } from "react";
import { ReactLenis } from "@studio-freight/react-lenis";
import LayoutHome from "./Layout/Home";
import LandingNew from "./page/LandingNew";
import FooterNew from "./components/FooterNew";
import webGLFluidEnhanced from "webgl-fluid-enhanced";

const App = () => {
  const { pathname } = useLocation();
  const canvasRef = useRef<any>(null);
  const [videoLoading, setVideoLoading] = useState(true);
  const { isDesktop, theme } = useContext(ContextProviderWrapper)!;
  useEffect(() => {
    webGLFluidEnhanced.simulation(canvasRef.current, {
      SIM_RESOLUTION: 256, // Resolution of the simulation grid
      DYE_RESOLUTION: 256, // Resolution of the dye grid
      CAPTURE_RESOLUTION: 128, // Resolution of captured frames
      DENSITY_DISSIPATION: 10, // Rate at which density dissipates
      VELOCITY_DISSIPATION: 0.1, // Rate at which velocity dissipates
      PRESSURE: 0.1,
      PRESSURE_ITERATIONS: 12, // Number of pressure iterations
      CURL: 2, // Curl value used in the simulation
      INITIAL: true, // Enables splats on initial load
      SPLAT_AMOUNT: 1, // Number of initial splats (Random number between n and n * 5)
      SPLAT_RADIUS: 0.25, // Radius of the splats
      // SPLAT_FORCE: 2000, // Force applied by the splats
      SPLAT_KEY: "", // Keyboard key to spawn new splats (empty to disable)
      SHADING: true, // Enables shading in the visualization
      // COLORFUL: false, // Enables rapid changing of colors
      // COLOR_UPDATE_SPEED: 1, // Speed of color update
      COLOR_PALETTE: ["#b3ebff"], // Custom color palette (empty by default, uses hex colors)
      HOVER: true, // Enables interaction on hover
      BACK_COLOR: `${theme === 'light' ? '#eeeef0' : '#141518' }`, // Background color of the canvas
      TRANSPARENT: false, // Makes the canvas transparent if true
      BRIGHTNESS: 1, // Color brightness (Recommend lower than 1.0 if BLOOM is true)
      BLOOM: false, // Enables bloom effect
      BLOOM_ITERATIONS: 1, // Number of bloom effect iterations
      BLOOM_RESOLUTION: 3, // Resolution of the bloom effect
      BLOOM_INTENSITY: 0.3, // Intensity of the bloom effect
      BLOOM_THRESHOLD: 0.8, // Threshold for the bloom effect
      BLOOM_SOFT_KNEE: 0.1, // Soft knee value for the bloom effect
      SUNRAYS: true, // Enables sunrays effect
      // SUNRAYS_RESOLUTION: 1000, // Resolution of the sunrays effect
      SUNRAYS_WEIGHT: 1.0, // Weight of the sunrays effect
    });
  }, [theme]);

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      setVideoLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isDesktop && (
        <LoadingPage
          className={videoLoading ? "show-loading" : "hidden-loading"}
        >
          <video autoPlay muted>
            <source src="./video/video_loading.mp4?v=5" type="video/mp4" />
          </video>
        </LoadingPage>
      )}

      <canvas
        ref={canvasRef}
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
        }}
      />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontFamily: "SF",
          },
        }}
      />
      <ReactLenis
        root
        options={{
          duration: pathname === "/" ? 3 : 1,
        }}
      >
        <AppContainer pathname={pathname === "/"}>
          <Routes>
            <Route path="/" element={<LayoutHome />}>
              {/* <Route path="" element={<Home />} /> */}
              {isDesktop ? (
                <>
                  {!videoLoading && <Route path="" element={<LandingNew />} />}
                </>
              ) : (
                <Route path="" element={<LandingNew />} />
              )}
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <FooterNew />
        </AppContainer>
      </ReactLenis>
    </>
  );
};

export default App;
