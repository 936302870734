import React, { useContext, useRef } from "react";
import {
  RoadmapContainer,
  RoadmapItem,
  RoadmapItemInner,
  RoadmapItemSubList,
  RoadmapItemSubListitem,
  RoadmapItemTitle,
  RoadmapList,
  RoadmapWrapper,
  RoadmapWrapperTitle,
} from "./styled";
import { useScroll, useTransform } from "framer-motion";
import { motion } from "framer-motion";
import { TitlePrimary } from "../../../assets/Common/TitleSection/styled";
import { ContextProviderWrapper } from "../../../components/Context";
import { Trans } from "react-i18next";

const RoadmapNew = () => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const backgroundPosition = useTransform(
    scrollYProgress,
    [0, 0.5],
    ["100%", "0%"]
  );
  return (
    <RoadmapContainer ref={ref}>
      <RoadmapWrapper>
        <RoadmapWrapperTitle>
          {/* <TitleSection title={dataTitle} /> */}
          <TitlePrimary className={`${theme === "light" ? "light" : ""} ${lng === 'vi' ? 'is-vi' : ''}`}>
            <motion.span
              style={{
                backgroundPositionX: (isDesktop || isTablet) && backgroundPosition,
              }}
            >
              <Trans>roadmap.roadmap</Trans>
            </motion.span>
          </TitlePrimary>
        </RoadmapWrapperTitle>
        <RoadmapList>
          {dataRoadmap.map((items: any, index: number) => (
            <ItemRoadmap index={index} items={items} />
          ))}
        </RoadmapList>
      </RoadmapWrapper>
    </RoadmapContainer>
  );
};

const dataRoadmap = [
  {
    id: 1,
    title: "roadmap.PHASE",
    listtext: [
      "roadmap.Launch Of Landing page",
      "roadmap.Completing Marketing Strategy",
      "roadmap.Onboarding Partners",
      "roadmap.Building Community",
      "roadmap.Researching & Developing Product",
    ],
    isArrow: true,
  },
  {
    id: 2,
    title: "roadmap.PHASE",
    listtext: [
      "roadmap.Releasing Kibble AMM",
      "roadmap.Initiating Kibble DEX Platform",
      "roadmap.Initiating Liquidity Pool & Farming",
      "roadmap.Initiating DEX on Mini App",
      "roadmap.Releasing Staking",
      "roadmap.Initiating Kibble Bot",
      "roadmap.Angel Round & Seed Round",
    ],
    isArrow: true,
  },
  {
    id: 3,
    title: "roadmap.PHASE",
    listtext: [
      "roadmap.Releasing Kibble DEX router",
      "roadmap.Initiating Single-Side Liquidity",
      "roadmap.Initiating Kibble Launchpad",
      "roadmap.Creating trading pairs in DEX",
      "roadmap.Listing $KIB Token",
    ],
  },
  {
    id: 4,
    title: "roadmap.PHASE",
    listtext: [
      "roadmap.Releasing Lending and Borrowing",
      "roadmap.DAO Governance",
      "roadmap.Releasing Margin Trading",
      "roadmap.Releasing Kibble Bridge",
      "roadmap.Releasing Launchpad Multichain",
      "roadmap.INO Testnet",
    ],
  },
];

export default RoadmapNew;

const ItemRoadmap = ({ index, items }: any) => {
  const { isDesktop, theme, isTablet, lng } = useContext(ContextProviderWrapper)!;
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 1"],
  });
  const leftProgress1 = useTransform(scrollYProgress, [0, 1], [-400, 0]);
  const leftProgress2 = useTransform(scrollYProgress, [0, 1], [-400, 0]);
  const rightProgress1 = useTransform(scrollYProgress, [0, 1], [-400, 0]);
  const rightProgress2 = useTransform(scrollYProgress, [0, 1], [-400, 0]);
  const rotate1 = useTransform(scrollYProgress, [0, 1], [-30, 0]);
  const rotate2 = useTransform(scrollYProgress, [0, 1], [-30, 0]);
  const rotate3 = useTransform(scrollYProgress, [0, 1], [30, 0]);
  const rotate4 = useTransform(scrollYProgress, [0, 1], [30, 0]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0, 1]);
  return (
    <RoadmapItem
      key={index}
      ref={ref}
      style={{
        left:
          items.id === 1
            ? (isDesktop || isTablet) && leftProgress1
            : items.id === 3
            ? (isDesktop || isTablet) && leftProgress2
            : "",
        right:
          items.id === 2
            ? (isDesktop || isTablet) && rightProgress1
            : items.id === 4
            ? (isDesktop || isTablet) && rightProgress2
            : "",
        rotate:
          items.id === 1
            ? (isDesktop || isTablet) && rotate1
            : items.id === 2
            ? (isDesktop || isTablet) && rotate3
            : items.id === 3
            ? (isDesktop || isTablet) && rotate2
            : (isDesktop || isTablet) && rotate4,
        opacity: opacityProgress,
      }}
      className={theme === 'light' ? 'light' : ''}
    >
      <RoadmapItemInner>
        {/* <RoadmapItemTitle className={items.isArrow && "is-arrow"} > */}
        <RoadmapItemTitle className={`${items.isArrow ? 'is-arrow' : ''} ${lng === 'vi' ? 'is-vi' : ''}`}>
          <Trans>{items.title}</Trans>
          <span>{items.id}</span>
        </RoadmapItemTitle>
        <RoadmapItemSubList>
          {items.listtext.map((item: any) => (
            <RoadmapItemSubListitem className={`${lng === 'vi' ? 'is-vi' : ''}`}><Trans>{item}</Trans></RoadmapItemSubListitem>
          ))}
        </RoadmapItemSubList>
      </RoadmapItemInner>
    </RoadmapItem>
  );
};
