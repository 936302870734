import styled from "styled-components";
import keyvisualBg from "../../../assets/LandingNew/Keyvisual/keyvisual_bg.png";
import keyvisualBg02 from "../../../assets/LandingNew/Keyvisual/keyvisual_bg_02.png";

export const KeyvisualNewContainer = styled.div`
  /* min-height: 100vh; */
  padding-top: 16vw;
  padding-bottom: 8vw;
  position: relative;
  margin-top: -20px;
  z-index: 1;
  background-color: #141518;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: url(${keyvisualBg}) center / cover no-repeat;
    width: 100%;
    height: 100%;
    animation: identifier 1s linear forwards;
    pointer-events: none;
    z-index: -1;
  }
  &.light {
    background-color: #F7F8FF;
    &:after {
      background-image: url(${keyvisualBg02});
    }
  }
  @keyframes identifier {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
    padding-top: 200px;
  }
`;
export const LandingNewWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 2vw;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;
export const KeyvisualTitle = styled.h1`
  max-width: 60.3477vw;
  width: 100%;
  margin: 0 auto 20px;
  animation: bounce-in 2s ease forwards;
  img {
    width: 100%;
  }
  @keyframes bounce-in {
    0% {
      opacity: 0;
      transform: scale(0.3);
    }
    50% {
      opacity: 1;
      transform: scale(1.1);
    }
    70% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 869px;
  }
`;
export const KeyvisualText = styled.p`
  max-width: 30vw;
  margin: 0 auto;
  text-align: center;
  font-size: 1.11111vw;
  line-height: 1.5vw;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    max-width: 310px;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const ButtonKeyvisual = styled.div`
  width: 2.8vw;
  height: 2.8vw;
  margin: 0 auto 50px;
  animation: bonce 2s linear infinite;
  img {
    width: 100%;
  }
  @keyframes bonce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
`;

export const ListButton = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #141518;
  position: relative;
  max-width: 19vw;
  min-width: 300px;
  margin: 0 auto 3vw;
  &.light {
    background-color: unset;
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    min-width: unset;
    margin-bottom: 40px;
    justify-content: center;
    gap: 10px;
  }
`