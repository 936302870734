import { motion } from "framer-motion";
import styled from "styled-components";

export const WhoContainer = styled.div`
  padding-top: 18vw;
  overflow: hidden;
`;
export const WhoWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 8vw;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;
export const WhoText = styled.p`
  color: #e0eeee;
  font-family: Syne;
  font-size: 1.388888vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7vw;
  max-width: 25vw;
  margin-left: auto;
  position: absolute;
  top: 18vw;
  right: 3vw;
  &.light {
    color: #43424a;
  }
  @media screen and (max-width: 767px) {
    font-size: 20px;
    position: static;
    margin-left: unset;
    max-width: unset;
    margin-bottom: 20px;
    line-height: 1.2;
  }
`;
export const WhoContentText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const WhoItem = styled(motion.li)`
  min-height: 16.8vw;
  border-radius: 20px;
  padding: 2.8vw;
  position: relative;
  margin-bottom: 20px;
  span {
    color: var(--Neutral-50, #f7f7f8);
    font-family: DMMono-400;
    font-size: 6.66667vw;
    font-style: normal;
    font-weight: 400;
    color: #000;
    position: relative;
    padding-right: 3vw;
    /* &:after {
      position: absolute;
      display: inline;
      content: "+";
      right: 0;
      top: -0.06vw;
      color: var(--Neutral-50, #f7f7f8);
      font-family: DMMono-400;
      font-size: 5vw;
      font-style: normal;
      font-weight: 400;
      color: #000;
    } */
  }
  p {
    color: var(--Neutral-50, #f7f7f8);
    text-align: right;
    font-family: Dirtyline;
    font-size: 1.944445vw;
    font-style: normal;
    font-weight: 400;
    line-height: 90%; /* 25.2px */
    text-transform: lowercase;
    margin-top: auto;
    max-width: 12vw;
    margin-left: auto;
    color: #000;
    position: absolute;
    right: 40px;
    bottom: 40px;
  }
  &:nth-child(1) {
    background-color: #48ceff;
    span,
    p {
      color: #fff;
    }
    span {
      &:after {
        color: #fff;
      }
    }
  }
  &:nth-child(2) {
    background-color: #d6f3ff;
  }
  &.light {
    &:nth-child(1) {
      background-color: #48ceff;
    }
    &:nth-child(2) {
      background-color: #b5ebff;
    }
  }
  &.is-vi {
    span {
      text-transform: uppercase !important;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    min-height: 150px;
    span {
      font-size: 50px;
      padding-right: 30px;
      &:after {
        font-size: 40px;
      }
    }
    p {
      max-width: 190px;
      font-size: 20px;
    }
  }
`;

export const WhoList = styled.ul`
  width: calc(50% - 10px);
  &.list-02 {
    margin-top: 250px;
    ${WhoItem} {
      span,
      p {
        color: #000;
      }
      &:nth-child(1) {
        background-color: #edfaff;
        span {
          &:after {
            color: #000;
          }
        }
      }
      &:nth-child(2) {
        background-color: #007af5;
        span,
        p {
          color: #f7f7f8;
        }
        span {
          &:after {
            color: #f7f7f8;
          }
        }
      }
      &.light {
        &:nth-child(1) {
          background-color: #0e335d;
          p,
          span {
            color: #f7f7f8;
          }
          span {
            &:after {
              color: #fff;
            }
          }
        }
        &:nth-child(2) {
          background-color: #007af5;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    &.list-02 {
      margin-top: 20px;
    }
  }
`;
