import styled from "styled-components";
import { motion } from "framer-motion";

export const PartnersContainer = styled.div`
  padding: 18vw 0;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;
export const PartnersWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 8vw;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;
export const PartnersList = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 3vw;
  margin-right: 16px;
  &.list-02 {
    margin-top: 1vw;
  }
  @media screen and (max-width: 767px) {
    margin-top: 20px;
    justify-content: space-between;
    &.list-02 {
      margin-top: 15px;
    }
  }
`;
export const PartnersItem = styled.div`
  width: 340px;
  min-height: 8.7vw;
  text-align: center;
  border-radius: 0.7vw;
  background: #232529;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    img {
      mix-blend-mode: normal;
    }
  }
  img {
    width: 15vw;
    transition: all 0.26s ease;
    mix-blend-mode: luminosity;
  }
  .image-normal {
    opacity: 1;
  }
  .image-hover {
    opacity: 0;
  }
  @media screen and (max-width: 767px) {
    width: 183px;
    padding: 10px 0;
    img {
      width: 130px;
    }
  }
  /* &.light {
    background-color: #d9d9de;
  } */
`;
